import React from "react";
import styled from "styled-components";
// import {bool, node} from "prop-types";
import {colors} from "@qc-modules/styles";
import {typography} from "styled-system";
import {Primitives, Glyph} from "@qc-modules/components";


const ToolWrapper = styled(Primitives.Box)`
  border-radius: 50%;
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  line-height: 0;
  vertical-align: middle;
`

const ToolGlyph = styled(Glyph)`
  height: 100%;
`

const ToolButton = styled(Primitives.Button)`
  cursor: pointer;
  &:focus {
    outline: none;
  }
`

const ToolWindow = styled(({isLarge, ...props}) => (
    <Primitives.Box {...props} />
))`
  border-radius: 8px;
  border: 1px solid ${props => colors("border", props)};
  position: absolute;
  left: 50%;
  height: 150px;
  width: ${({isLarge}) => (isLarge ? 400 : 200)}px;
  bottom: calc(0.7em + 14px);
  transform: translateX(-50%);
  z-index: 10;
  ${typography};
  &::before {
    content: "";
    background: inherit;
    display: block;
    position: absolute;
    left: 50%;
    bottom: -9px;
    height: 8px;
    width: 8px;
    transform-origin: 0 0;
    border-left: 1px solid ${props => colors("border", props)};
    border-bottom: 1px solid ${props => colors("border", props)};
    transform: translateX(-6px) rotate(-45deg);
  }
`

// const propTypes = {
//     /** ToolWindow child components */
//     children: node,
//     /** A boolean to represent whether the tooltip is large */
//     isLarge: bool,
//     /** A boolean to represent whether the tooltip is open */
//     isOpen: bool
// }
//
// const defaultProps = {
//     isOpen: false
// }


// const toolWindowProps = {
//     backgroundColor: "gunmetal",
//     border: "1px solid",
//     borderColor: "gunmetal",
//     borderRadius: "8px",
//     color: "white",
//     fontSize: 2,
//     left: "50%",
//     lineHeight: 3,
//     p: 2,
//     position: "absolute",
//     top: "calc(0.7em + 17px)",
//     zIndex: 10
// }

const QidTooltipComponent = ({children, isLarge, toggleIsOpen, isOpen}) => {
    return (
        <ToolWrapper forwardedAs="span" height={16} mx={1} width={16}>
            <ToolButton
                display="inline-block"
                height={1}
                onMouseEnter={toggleIsOpen}
                onMouseLeave={toggleIsOpen}
                tabIndex={0}
                width={1}
                type={'button'}
            >
                <ToolGlyph fill="icon" id="info"/>
            </ToolButton>
            {isOpen ? (
                <ToolWindow
                    backgroundColor="background"
                    color="text-title"
                    fontSize={2}
                    forwardedAs="span"
                    isLarge={isLarge}
                    lineHeight={3}
                    p={2}
                >
                    {children}
                </ToolWindow>
            ) : null}
        </ToolWrapper>
    )
}


export default QidTooltipComponent
