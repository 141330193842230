import React from 'react';
import {Field} from "formik";
import {WrappedFormComponents} from "../../common/FormikWrappedComponents";
import {FieldRowFlex, FieldValidationError} from "./Helper";
import {BillingValidationFunctions} from "../../../utils/validation/BillingValidationFunctions";

const UserContactForm = () => {
    return (
        <>
            <FieldRowFlex>
                <Field name='billingName' id={'billingName'} label={'Primary Contact Name*'} type='text'
                       component={WrappedFormComponents.InputWrapper} width={1}
                       validate={BillingValidationFunctions.validateBillingName}/>
                <FieldValidationError name={'billingName'}/>
            </FieldRowFlex>
            <FieldRowFlex>
                <Field name='billingPhone' id={'billingPhone'} label={'Primary Contact Phone*'} type='text'
                       component={WrappedFormComponents.InputWrapper} width={1}
                       validate={BillingValidationFunctions.validateBillingPhone}/>
                <FieldValidationError name={'billingPhone'}/>
            </FieldRowFlex>
            <FieldRowFlex>
                <Field name='billingEmail' id={'billingEmail'} label={'Primary Contact Email*'} type='text'
                       component={WrappedFormComponents.InputWrapper} width={1}
                       validate={BillingValidationFunctions.validateBillingEmail}/>
                <FieldValidationError name={'billingEmail'}/>
            </FieldRowFlex>
        </>
    )
}

export default UserContactForm;
