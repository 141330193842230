import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import authService from "../services/authService";

const ProtectedRoute = ({component: Component, ...rest}) => {

    const internalUserDefaultRoute = '/';
    const forceToInternalUserPage = () => {
        // const {path} = {...rest}
        const isUserLoggedIn = authService.getCurrentUser();
        if (isUserLoggedIn) {
            const isExternalUser = authService.isExternalUser();
            return !isExternalUser //internalUserRestrictedRoutes.indexOf(path) > -1 &&
        }
    };
    return (
        <Route
            {...rest}
            render={props => {

                const token = authService.readTokenFromParams(props);
                if (token) {
                    authService.loginWithToken(token);

                    //set the search param bck to null or we will get into an endless loop
                    //because it will keep pulling the token off each time and keep going thru the process
                    props.location.search = null;
                    props.history.push(props.location);
                    if (forceToInternalUserPage()) {
                        return <Redirect
                            to={internalUserDefaultRoute}
                        />
                    }
                    return <Component {...props} />;
                }

                if (authService.getCurrentUser()) {
                    props.location.search = null;
                    if (forceToInternalUserPage()) {
                        return <Redirect
                            to={internalUserDefaultRoute}
                        />
                    }
                    return <Component {...props} />;
                }

                return (
                    <Redirect
                        to={{
                            pathname: '/auth',
                            state: {from: props.location}
                        }}
                    />
                );
            }}
        />
    );
};

export default ProtectedRoute;
