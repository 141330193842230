import React, {useState} from 'react';
import {Field} from "formik";
import {WrappedFormComponents} from "../../common/FormikWrappedComponents";
import {
    DataBox, PageHeading, SectionHeading, StyledComponentSubheading, StyledShippingDataBox,
    StyledShippingDataContainer, StyledShippingSubheadingContainer
} from "./RegistrationStyle";
import {FieldRowFlex, FieldValidationError} from "./Helper";
import {Checkbox} from "@qc-modules/components";
import AdditionalContactForm from "./AdditionalContactForm";
import {ShippingValidationFunctions} from "../../../utils/validation/ShippingValidationFunctions";
import QidTooltipComponent from "../../tooltip-component";

const ShippingForm = ({orgData, values, setFieldValue}) => {
    return (
        <>
            <PageHeading text="Complete your company shipping and delivery info."
                         testid="registration-shipping-header"/>

            <StyledShippingSubheadingContainer>
                <SectionHeading text="Ship to"/>

                <FieldRowFlex data-testid='shipping-same-as-billing-check'>
                    <Checkbox
                        name="shippingSameAsBilling"
                        id="shippingSameAsBilling"
                        isChecked={values.shippingSameAsBilling}
                        label={'Use my billing info'}
                        checkboxOnChange={(e) => {
                            e.preventDefault();
                            setFieldValue("shippingSameAsBilling", !values.shippingSameAsBilling)}}
                    />
                </FieldRowFlex>
            </StyledShippingSubheadingContainer>

            <StyledShippingDataContainer>
                {values.shippingSameAsBilling
                    ?
                    <ShippingFieldsReadOnly orgData={orgData} values={values}/>
                    :
                    <ShippingFieldsEditable orgData={orgData} values={values}/>
                }
            </StyledShippingDataContainer>

        </>
    )
}

export default ShippingForm;

const ShippingFieldsReadOnly = ({orgData, values}) => {
    const countryDisplay = orgData.countries.find((country) => country.code === values.billingCountry)

    return (
        <>
            <StyledShippingDataBox>
                <StyledComponentSubheading>{orgData.org.name}</StyledComponentSubheading>
                <DataBox>
                    {values.billingAddress}
                    <>{values.billingCity}, {values.billingState} {values.billingPostalCode}</>
                    {countryDisplay.name}
                </DataBox>
            </StyledShippingDataBox>

            <StyledShippingDataBox>
                <StyledComponentSubheading>{values.billingName}</StyledComponentSubheading>
                <DataBox>
                    {values.billingPhone}
                    {values.billingEmail}
                </DataBox>
            </StyledShippingDataBox>

            <ShippingTaxStatus orgData={orgData}/>
        </>
    )
}

const ShippingFieldsEditable = ({orgData, values}) => {
    const matchingCountry = orgData && orgData.countryStateMap && orgData.countryStateMap.find(obj => obj.code === values.shippingCountry);
    const countryHasStates = matchingCountry && !!matchingCountry.states
    let stateOptions = matchingCountry && matchingCountry.states && matchingCountry.states.map((state) => ({
        id: state.code,
        value: state.code,
        label: state.name
    }));
    stateOptions && stateOptions.unshift({id: 'selectOne', value: '', label: 'Select One'});
    orgData.states = stateOptions;

    return (
        <>
            <FieldRowFlex data-testid={'shippingLegalCompanyName'}>
                <Field name='shippingLegalCompanyName' id='shippingLegalCompanyName' label='Legal Company Name*'
                       type='text'
                       component={WrappedFormComponents.InputWrapper} width={1}
                       validate={ShippingValidationFunctions.validateShippingCompanyName}/>
                <FieldValidationError name={'shippingLegalCompanyName'}/>
            </FieldRowFlex>
            <FieldRowFlex>
                <Field name='shippingAddress' id='shippingAddress' label='Address*'
                       type='textarea' rows={3} height='90px'
                       component={WrappedFormComponents.InputWrapper}
                       width={1}
                       validate={ShippingValidationFunctions.validateShippingAddress}
                />
                <FieldValidationError name={'shippingAddress'}/>
            </FieldRowFlex>

            <FieldRowFlex>
                <Field name='shippingCity' id='shippingCity' label='City*' type='text'
                       component={WrappedFormComponents.InputWrapper}
                       width={1}
                       validate={ShippingValidationFunctions.validateShippingCity}
                />
                <FieldValidationError name={'shippingCity'}/>
            </FieldRowFlex>

            <FieldRowFlex>
                <Field
                    as="select"
                    name="shippingCountry"
                    id="shippingCountry"
                    label="Country*"
                    labelPosition='placeholder'
                    width={1}
                    options={orgData.countryOptions}
                    component={WrappedFormComponents.SelectWrapper}
                    validate={ShippingValidationFunctions.validateShippingCountry}
                />
                <FieldValidationError name={'shippingCountry'}/>
            </FieldRowFlex>

            {(countryHasStates ) &&
            <FieldRowFlex>
                <Field
                    as="select"
                    name="shippingState"
                    id="shippingState"
                    label="State*"
                    labelPosition='placeholder'
                    width={1}
                    options={orgData.states}
                    component={WrappedFormComponents.SelectWrapper}
                    validate={ShippingValidationFunctions.validateShippingState}
                />
                <FieldValidationError name={'shippingState'}/>
            </FieldRowFlex>
            }

            {(!countryHasStates ) &&
            <FieldRowFlex>
                <Field name='shippingProvince' id='shippingProvince' label='Province' type='text'
                       component={WrappedFormComponents.InputWrapper} width={1}
                       validate={ShippingValidationFunctions.validateShippingProvince}
                />
                <FieldValidationError name={'shippingProvince'}/>
            </FieldRowFlex>
            }

            <FieldRowFlex>
                <Field name='shippingPostalCode' id='shippingPostalCode' label='Postal Code*' type='text'
                       component={WrappedFormComponents.InputWrapper} width={1}
                       validate={ShippingValidationFunctions.validateShippingPostalCode}
                       bottomLabel={"If postal code does not apply, enter 0000"}
                />
                <FieldValidationError name={'shippingPostalCode'}/>
            </FieldRowFlex>

            <FieldRowFlex>
                <Field name='shippingContactName' id='shippingContactName' label={'Primary Contact Name*'} type='text'
                       component={WrappedFormComponents.InputWrapper} width={1}
                       validate={ShippingValidationFunctions.validateShippingName}
                />
                <FieldValidationError name={'shippingContactName'}/>
            </FieldRowFlex>
            <FieldRowFlex>
                <Field name='shippingContactPhone' id={'shippingContactPhone'} label={'Primary Contact Phone*'}
                       type='text'
                       component={WrappedFormComponents.InputWrapper} width={1}
                       validate={ShippingValidationFunctions.validateShippingPhone}
                />
                <FieldValidationError name={'shippingContactPhone'}/>
            </FieldRowFlex>
            <FieldRowFlex>
                <Field name='shippingEmail' id={'shippingEmail'} label={'Primary Contact Email*'} type='text'
                       component={WrappedFormComponents.InputWrapper} width={1}
                       validate={ShippingValidationFunctions.validateShippingEmail}
                />
                <FieldValidationError name={'shippingEmail'}/>
            </FieldRowFlex>

            <FieldRowFlex>
                <AdditionalContactForm namespace={'shippingAdditionalContacts'} values={values}/>
            </FieldRowFlex>

            <ShippingTaxStatus orgData={orgData}/>
        </>
    )
}

const ShippingTaxStatus = ({orgData}) => {
    const [toolTip, setToolTip] = useState(false)

    const toggleToolTip = () => {
        setToolTip(!toolTip)
    }

    const bottomLabel = () => {
        return (
            <QidTooltipComponent isOpen={toolTip} toggleIsOpen={toggleToolTip} isLarge={true}>
                If required, email certificate to <a
                href='mailto:salesops.testtools@qti.qualcomm.com'>salesops.testtools@qti.qualcomm.com</a>.
            </QidTooltipComponent>
        )
    }

    return (
        <FieldRowFlex>
            <Field
                as="select"
                name="shippingTaxStatus"
                id="shippingTaxStatus"
                label="Tax Status*"
                labelPosition='placeholder'
                width={1}
                options={orgData.taxStatusOptions}
                component={WrappedFormComponents.SelectWrapper}
                validate={ShippingValidationFunctions.validateTaxStatusRequired}
                bottomLabel={bottomLabel()}
            />
            <FieldValidationError name={'shippingTaxStatus'}/>
        </FieldRowFlex>
    )
};
