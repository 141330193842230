import React, {useEffect, useState} from 'react';
import history from "../../../context/History";
import RegistrationForm from "./RegistrationForm";
import {submitRegistration, validateToken} from "../../../api/register-api";
import {registrationConstants} from "../../../utils/registrationConstants";
import RegisterResponse from "./RegisterResponse";
import Loading from "../../common/Loading";

const Registration = () => {
    const [orgData, setOrgData] = useState({});
    const [loading, setLoading] = useState(true);

    const handleSubmit = async (values, helpers) => {
        setLoading(true);
        submitRegistration(values)
            .then(() => {
                helpers.setSubmitting(false);
                setLoading(false);
                history.push({
                    pathname: '/sales/activate/response',
                    responseType: registrationConstants.ResponseType.SUCCESS
                })

            })
            .catch(err => {
                helpers.setSubmitting(false);
                setLoading(false)
                history.push('/error');
            })
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await validateToken();
                setOrgData(data);
                setLoading(false);
            } catch (e) {
                history.push('/error');
            }
        };
        fetchData();

    }, []);

    return (
        <>
            {loading ? <Loading/> :

                (
                    (orgData && orgData.user && orgData.org && !orgData.org.srfSalesSubmitted)
                        ?
                        <RegistrationForm orgData={orgData} handleSave={handleSubmit} loading={loading}/>
                        :
                        <RegisterResponse responseType={registrationConstants.ResponseType.DUPLICATE}/>
                )
            }
        </>
    )
}

export default Registration;
