import React from 'react';

const Landing = ()=> {
    return(
        <div>
            QID Supplemental Registration Forms
        </div>
    )
};

export default Landing;