import styled from "styled-components";
import {Button} from "@qc-modules/components";
import {colors} from "@qc-modules/styles";

export const getLogoutUri = () => {
    return `${process.env.REACT_APP_REDIRECT_LOGOUT_URI}`;
};
export const getRefreshTokenUrl = () => {
    return `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_TOKEN_REFRESH_ENDPOINT}`;
};
export const getLoginUrl = () => {
    return `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_LOGIN_ENDPOINT}`;
};


export const ActionLink = styled(Button)`
    font-size: 16px;
    color: ${props => colors("blue", props)};
    &:hover {color: #1C37A5}; cursor: pointer;
    &:active {color: ${props => colors("blue", props)};
    &:visited {color: ${props => colors("blue", props)};
    `;