import React from 'react'
import {validations} from "./ValidationSchema";
import {getInvalidGlyph, StyledErrorMessage} from "./ValidationStyles";

const validateDeliveryCompanyName = (value) => {
    const fieldName = 'legalCompanyName';
    const data = setupFieldData(fieldName, value);

    try {
        validations.companyNameValidationRequired(fieldName).validateSync(data);
    } catch (err) {
        return <StyledErrorMessage>{getInvalidGlyph()} {err.message}</StyledErrorMessage>
    }
}

const validateDeliveryAddress = (value) => {
    const fieldName = 'deliveryAddress';
    const data = setupFieldData(fieldName, value);

    try {
        validations.addressValidationRequired(fieldName).validateSync(data);
    } catch (err) {
        return <StyledErrorMessage>{getInvalidGlyph()} {err.message}</StyledErrorMessage>
    }
}

const validateDeliveryEmail = (value) => {
    const fieldName = 'deliveryEmail';
    const data = setupFieldData(fieldName, value);

    try {
        validations.emailValidationRequired(fieldName).validateSync(data);
    } catch (err) {
        return <StyledErrorMessage>{getInvalidGlyph()} {err.message}</StyledErrorMessage>
    }
}

const validateDeliveryState = (value) => {
    const fieldName = 'deliveryState';
    const data = setupFieldData(fieldName, value);

    try {
        validations.stateValidationRequired(fieldName).validateSync(data);
    } catch (err) {
        return <StyledErrorMessage>{getInvalidGlyph()} {err.message}</StyledErrorMessage>
    }
}

const setupFieldData = (fieldName, value) => {
    return {[fieldName]: value};
}

const validateDeliveryPostalCode = (value) => {
    const fieldName = 'deliveryPostalCode';
    const data = setupFieldData(fieldName, value);

    try {
        validations.postalCodeValidation(fieldName).validateSync(data);

    } catch (err) {
        return <StyledErrorMessage>{getInvalidGlyph()} {err.message}</StyledErrorMessage>
    }
}

const validateDeliveryProvince = (value) => {
    const fieldName = 'deliveryProvince';
    const data = setupFieldData(fieldName, value);

    try {
        validations.provinceValidation(fieldName).validateSync(data);
    } catch (err) {
        return <StyledErrorMessage>{getInvalidGlyph()} {err.message}</StyledErrorMessage>
    }
}

const validateDeliveryCity = (value) => {
    const fieldName = 'deliveryCity';
    const data = setupFieldData(fieldName, value);

    try {
        validations.cityValidationRequired(fieldName).validateSync(data);
    } catch (err) {
        return <StyledErrorMessage>{getInvalidGlyph()} {err.message}</StyledErrorMessage>
    }
}

const validateDeliveryCountry = (value) => {
    const fieldName = 'deliveryCountry';
    const data = setupFieldData(fieldName, value);

    try {
        validations.countryValidationRequired(fieldName).validateSync(data);
    } catch (err) {
        return <StyledErrorMessage>{getInvalidGlyph()} {err.message}</StyledErrorMessage>
    }
}
const validateDeliveryName = (value) => {
    const fieldName = 'deliveryName';
    const data = setupFieldData(fieldName, value);

    try {
        validations.nameValidationRequired(fieldName).validateSync(data);
    } catch (err) {
        return <StyledErrorMessage>{getInvalidGlyph()} {err.message}</StyledErrorMessage>
    }
}

const validateDeliveryPhone = (value) => {
    const fieldName = 'deliveryPhone';
    const data = setupFieldData(fieldName, value);

    try {
        validations.phoneValidationRequired(fieldName).validateSync(data);
    } catch (err) {
        return <StyledErrorMessage>{getInvalidGlyph()} {err.message}</StyledErrorMessage>
    }
}

export const DeliveryValidationFunctions = {
    validateDeliveryEmail,
    validateDeliveryAddress,
    validateDeliveryCity,
    validateDeliveryCompanyName,
    validateDeliveryCountry,
    validateDeliveryName,
    validateDeliveryPhone,
    validateDeliveryState,
    validateDeliveryPostalCode,
    validateDeliveryProvince,
};