import React from 'react';
import {Field} from "formik";
import {Box} from '@qc-modules/components';
import {WrappedFormComponents} from "../../common/FormikWrappedComponents";
import {PageHeading, SectionHeading} from "./RegistrationStyle";
import OrgDetail from "./OrgDetail";
import {FieldRowFlex, FieldValidationError} from "./Helper";
import {BillingValidationFunctions} from '../../../utils/validation/BillingValidationFunctions'


const BillingForm = ({orgData, values}) => {
    const matchingCountry = orgData && orgData.countryStateMap && orgData.countryStateMap.find(obj => obj.code === values.billingCountry);
    const countryHasStates = matchingCountry && !!matchingCountry.states
    let stateOptions = matchingCountry && matchingCountry.states && matchingCountry.states.map((state) => ({
        id: state.code,
        value: state.code,
        label: state.name
    }));
    stateOptions && stateOptions.unshift({id: 'selectOne', value: '', label: 'Select One'});
    orgData.states = stateOptions;


    return (
        <>
            <PageHeading text="Complete your company billing info." testid="billing-header"/>

            <Box my={5}>
                <OrgDetail orgData={orgData.org}/>
            </Box>

            <SectionHeading text="Bill to"/>
            <FieldRowFlex>
                <Field name='billingAddress' id='billingAddress' label='Address*'
                       type='textarea' rows={3} height='90px'
                       component={WrappedFormComponents.InputWrapper} width={1}
                       validate={BillingValidationFunctions.validateBillingAddress}/>
                <FieldValidationError name={'billingAddress'}/>
            </FieldRowFlex>

            <FieldRowFlex>
                <Field name='billingCity' id='billingCity' label='City*' type='text'
                       component={WrappedFormComponents.InputWrapper}
                       width={1}
                       validate={BillingValidationFunctions.validateBillingCity}/>
                <FieldValidationError name={'billingCity'}/>
            </FieldRowFlex>

            <FieldRowFlex>
                <Field
                    as="select"
                    name="billingCountry"
                    id="billingCountry"
                    label="Country*"
                    labelPosition='placeholder'
                    width={1}
                    options={orgData.countryOptions}
                    component={WrappedFormComponents.SelectWrapper}
                    validate={BillingValidationFunctions.validateBillingCountry}
                />
                <FieldValidationError name={'billingCountry'}/>
            </FieldRowFlex>

            {(countryHasStates) &&
            <FieldRowFlex>
                <Field
                    as="select"
                    name="billingState"
                    id="billingState"
                    label="State*"
                    labelPosition='placeholder'
                    width={1}
                    options={orgData.states}
                    component={WrappedFormComponents.SelectWrapper}
                    validate={BillingValidationFunctions.validateBillingState}
                >All tools and software</Field>
                <FieldValidationError name={'billingState'}/>
            </FieldRowFlex>}

            {(!countryHasStates) &&
            <FieldRowFlex>
                <Field name='billingProvince' id='billingProvince' label='Province' type='text'
                       component={WrappedFormComponents.InputWrapper} width={1}
                       validate={BillingValidationFunctions.validateBillingProvince}/>
                <FieldValidationError name={'billingProvince'}/>
            </FieldRowFlex>}

            <FieldRowFlex>
                <Field name='billingPostalCode' id='billingPostalCode' label='Postal Code*' type='text'
                       component={WrappedFormComponents.InputWrapper} width={1}
                       validate={BillingValidationFunctions.validateBillingPostalCode}
                       bottomLabel={"If postal code does not apply, enter 0000"}
                />
                <FieldValidationError name={'billingPostalCode'}/>
            </FieldRowFlex>

            <FieldRowFlex>
                <Field name='billingTaxNum' id='billingTaxNum' label={'Tax/VAT Registration Number*'} type='text'
                       component={WrappedFormComponents.InputWrapper} width={1}
                       validate={BillingValidationFunctions.validateBillingTaxNum}/>
                <FieldValidationError name={'billingTaxNum'}
                />
            </FieldRowFlex>
        </>
    )
}

export default BillingForm;
