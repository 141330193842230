import React, {useEffect} from 'react';
import BillingForm from "./BillingForm";
import UserContactForm from "./UserContactForm";
import AdditionalContactForm from "./AdditionalContactForm";
import AidContactForm from "./AidContactForm";

const BillingAndContactForm = ({orgData, values}) => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <BillingForm orgData={orgData} values={values}/>
            <UserContactForm/>
            <AdditionalContactForm namespace={'billingAdditionalContacts'} values={values}/>
            <AidContactForm/>
        </>
    )
}

export default BillingAndContactForm;
