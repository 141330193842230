import React, {useState} from 'react';
import {Route, Router, Switch} from 'react-router-dom';
import history from "../context/History";
import salesCenterRoutes from './SalesCenterRoutes';
import NotFound from "../components/common/NotFound";
import Landing from "../components/common/Landing";
import {getLoginUrl} from '../utils/helper';
import Loading from "../components/common/Loading";

const AppRouter = () => {
    const [returnUrl] = useState(window.location.href);
    const redirectToOauth = (returnUrl) => {
        window.location = targetUrl(returnUrl);
    };
    const targetUrl = (currentRoute) => {
        const url = `${getLoginUrl()}?state=${currentRoute}`;
        return (url);
    };

    return (
        <Router history={history}>
            <Switch>
                <Route path="/" exact component={Landing} key='landing'/>
                {salesCenterRoutes}
                <Route path='/auth' render={(props) => {
                    redirectToOauth(returnUrl);
                    return <Loading {...props} />
                }}/>
                <Route component={NotFound}/>

            </Switch>
        </Router>
    )
};

export default AppRouter;