import React from 'react';
import styled from 'styled-components';
import {Text, Box, Flex} from '@qc-modules/components';
import {device} from "../../../lib/breakpoints";

const StyledSectionHeading = styled(Text)`
    font-size: 24px;
    letter-spacing: .5px;
    margin-bottom: 10px;
    margin-top: 5px;
    color: #677283;
    word-wrap: break-word;
    font-weight: 500;
    line-height: 24px;
    
    @media ${device.tablet} {
    font-size: 26px;
    line-height: 27px;
    }
`;

const StyledSectionSubheading = styled(Text)`
    font-size: 16px;
    letter-spacing: .5px;
    margin-bottom: 10px;
    margin-top: 5px;
    color: #677283;
    word-wrap: break-word;
    font-weight: 500;
    line-height: 24px;
    
    @media ${device.tablet} {
    font-size: 20px;
    line-height: 27px;
    }
`;

export const StyledComponentTitle = styled(Text)`
  width: 440px;
  color: #222222;
  font-size: 36px;
  font-weight: 100;
  letter-spacing: 0.1px;
  line-height: 45px;
`;

export const StyledComponentSubheading = styled(Text)`
  height: 24px;
  width: 74px;
  color: #092643;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 24px;
`;


export const StyledComponentText = styled(Text)`
height: 24px;
 height: 78px;
  width: 168px;
  color: #222222;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 24px;
`;


const StyledInfoBox = styled(Box)`
    font-size: 16px;
    letter-spacing: .5px;
    margin-bottom: 10px;
    margin-top: 5px;
    color: #677283;
    word-wrap: break-word;
    font-weight: 400;
    line-height: 24px;
    
    @media ${device.tablet} {
    font-size: 18px;
    line-height: 27px;
    }
`

export const StyledButtonContainer = styled(Flex)`
    margin-top: 64px;
    align-items: flex-start;
    
    & button {
        margin-right: 46px;
    }
`

export const StyledShippingSubheadingContainer = styled(Box)`
    margin-top: 87px;
    margin-bottom: 40px;
`

export const StyledShippingDataContainer = styled(Box)`
    margin-bottom: 45px;
`

export const StyledShippingInfoContainer = styled(Box)`
    margin-bottom: 32px;
`

export const StyledShippingDataBox = styled(Box)`
    margin-bottom: 32px;
    
    & + & {
        margin-bottom: 40px;
    }
`
export const StyledContactContainer = styled(Flex)`
    flex-direction: column;
    background-color: #F6F8FA;
    padding: 20px;
    border-radius: 12px;
    margin-top: 20px;
    margin-bottom: 5px;
    margin-left: -24px;
    margin-right: -24px;
`

export const SectionHeading = ({text, testid=''}) => {
    return (
        <StyledSectionHeading tag='h2' role='heading' aria-level='2' data-testid={testid}>
       {text}
        </StyledSectionHeading>
    )
};

export const SectionSubheading = ({text, testid=''}) => {
    return (
        <StyledSectionSubheading tag='h3' role='heading' aria-level='3' data-testid={testid}>
            {text}
        </StyledSectionSubheading>
    )
};

export const PageHeading = ({text, testid=''}) => {
    return (
        <StyledComponentTitle tag='h2' role='heading' aria-level='2' data-testid={testid}>
            {text}
        </StyledComponentTitle>
    )
};

export const InfoBox = ({children, testid=''})=> {
    return(
        <StyledInfoBox data-testid={testid}>
            {children}
        </StyledInfoBox>
    )
}

export const DataBox = ({children, testid=''})=> {
    let dataResults = children.map((value) => {
        return <StyledInfoBox>{value}</StyledInfoBox>;
    })

    return(
        <>
            {dataResults}
        </>
    )
}

export const ComponentTitle = ({text, testid=''}) => {
    return (
        <StyledComponentTitle tag='h2' role='heading' aria-level='1' data-testid={testid}>
            {text}
        </StyledComponentTitle>
    )
};


