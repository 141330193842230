export const registrationConstants = {
    REGEX: {
        ADDRESS: /^[a-zA-Z0-9 ,.\-()#&:;\n/]*$/,
        CITY: /^[a-zA-Z0-9 ,.\-()'/]*$/,
        POSTAL_CODE: /^[a-zA-Z0-9 ,.\-/]*$/,
        PROVINCE: /^[a-zA-Z0-9 ,.\-()'/]*$/,
        STATE_OR_REGION: /^[a-zA-Z0-9 ,.\-()'/]*$/,
        INITIAL_CHECK: /^[^\s]{2,}?([\s]*[^\s]{2,}?)*$/,
        FIRST_LAST_NAME: /^[a-zA-Z0-9- "().']*$/,
        TAX_VAT: /^[a-zA-Z0-9- "()'._]*$/,
        TAX_STATUS: /^[a-zA-Z0-9- "()']*$/,
        PHONE_NUMBER: /^[a-zA-Z0-9 +\-().#:]*$/,
        COMPANY: /^[a-zA-Z0-9 ,.\-()'/&]*$/,
        EMAIL: /^[a-zA-Z0-9._-]{2,}@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
    },
    maxLengthMsg: (field, length) => {
        return `Please supply at most ${length} characters`
    },
    minLengthMsg: (field, length) => {
        return `Please supply at least ${length} characters`
    },
    requiredMsg: (field) => {
        return `${field} is required`
    },
    invalidCharacterMsg: 'You have entered an invalid character',
    initialCheckMsg: 'You have entered a single initial. Please supply your full name',
    invalidEmailMsg: 'The email address you entered is not valid.',
    ResponseType: {
        SUCCESS: 'success',
        DUPLICATE: 'duplicateAttempt',
        ACCIDENT: 'accident'
    },
    MIN_NAME_CHARACTERS:2,
    MAX_NAME_CHARACTERS:64,
    MIN_PROVINCE_CHARACTERS:2,
    MAX_PROVINCE_CHARACTERS:128,
    MIN_ADDRESS_CHARACTERS:2,
    MAX_ADDRESS_CHARACTERS:256,
    MIN_PHONE_CHARACTERS:2,
    MAX_PHONE_CHARACTERS:128,
    MAX_EMAIL_CHARACTERS:80,
}
