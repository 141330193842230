import React from 'react'
import {validations} from "./ValidationSchema";
import {getInvalidGlyph, StyledErrorMessage,} from "./ValidationStyles";

const setupFieldData = (fieldName, value) => {
    return {[fieldName]: value};
}

const validateContactPhone = (value) => {
    const fieldName = 'phone';
    const data = setupFieldData(fieldName, value);

    try {
        validations.phoneValidation(fieldName).validateSync(data);
    } catch (err) {
        return <StyledErrorMessage>{getInvalidGlyph()} {err.message}</StyledErrorMessage>
    }
}

const validateContactName = (value) => {
    const fieldName = 'name';
    const data = setupFieldData(fieldName, value);

    try {
        validations.nameValidation(fieldName).validateSync(data);
    } catch (err) {
        return <StyledErrorMessage>{getInvalidGlyph()} {err.message}</StyledErrorMessage>
    }
}

const validateContactEmail = (value) => {
    const fieldName = 'email';
    const data = setupFieldData(fieldName, value);

    try {
        validations.emailValidation(fieldName).validateSync(data);
    } catch (err) {
        return <StyledErrorMessage>{getInvalidGlyph()} {err.message}</StyledErrorMessage>
    }
}

export const ValidationFunctions = {
    validateContactPhone,
    validateContactName,
    validateContactEmail
};