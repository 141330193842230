import axios from 'axios';
import {getRefreshTokenUrl, getLogoutUri} from "../utils/helper";

const jwt = require('jsonwebtoken');
const authAxios = axios.create();

authAxios.interceptors.request.use((config) => {
    return config;
}, function (error) {
    if (error && error.response && error.response.status === 401) {
        logout(window.location)
    } else {
        throw  error
    }
});

const tokenKey = `QID-${process.env.REACT_APP_ENV_SN}-JWT-TOKEN`;

export const setSession = (value) => {
    localStorage.setItem(tokenKey, value);
};

export const getSessionToken = () => {
    return localStorage.getItem(tokenKey);
};

export function removeSessionToken() {
    localStorage.removeItem(tokenKey);
}

export const readTokenFromParams = (props) => {
    const search = props.location.search || null;
    let token;
    if (search) {
        const params = new URLSearchParams(search);
        token = params.get('token');
    }
    return token;
};

export const getCookie = (cookieName) => {
    let i, x, y, cookieArray = document.cookie.split(";");
    for (i = 0; i < cookieArray.length; i++) {
        x = cookieArray[i].substr(0, cookieArray[i].indexOf("="));
        y = cookieArray[i].substr(cookieArray[i].indexOf("=") + 1);
        x = x.replace(/^\s+|\s+$/g, "");
        if (x === cookieName) {
            return unescape(y);
        }
    }
    return null;
};

export const isTokenExpired = (jwtToken) => {
    const token = jwtToken || getSessionToken();
    const user = token && jwt.decode(token);
    let softexp = user && user.exp - 300;
    if(Math.floor(Date.now() / 1000) > softexp) {
        return true
    }
    return false
};

let refreshPromise = null;

export const refreshToken = async (expiredToken) => {
        const decodedToken = jwt.decode(expiredToken);

        if(!refreshPromise) {
            refreshPromise = authAxios.get(`${getRefreshTokenUrl()}?sessionId=${decodedToken.sessionId}`);

            refreshPromise.then((response) => {
                const token = response.data && response.data.jwtToken  ? response.data.jwtToken : null;
                if (token) {
                    setSession(token);
                    return token;
                } else {
                    logout(window.location)
                }
            }).catch((error) => {
                throw  error
            }).finally(function() {
                refreshPromise = null;
            });
        }

        return refreshPromise;
};

// export const getSessionTokenRefresh = async (token) => {
//     const response = await refreshToken(token);
//     token = response.data ? response.data.jwtToken : null;
//     return token
// };
const isLocalEnv =()=>{
    const url = new URL(window.location);
    return url && url.hostname === 'localhost'
};

export const isValidSession = (token)=>{
    const user = token && jwt.decode(token);
    const qidSessionCookieName = ('prd' === process.env.REACT_APP_ENV_SN) ? 'QID_SESSION' : `QID_${process.env.REACT_APP_ENV_SN.toUpperCase()}_SESSION`;
    const qidSessionCookieValue = getCookie(qidSessionCookieName);
    const localStorageToken= user && user.sessionId;
    return ( !!qidSessionCookieValue && (localStorageToken === qidSessionCookieValue) ) || isLocalEnv()
};

export function getCurrentUser() {
    try {
        const token = getSessionToken();
        if (token) {
            if (!isValidSession(token) || isTokenExpired(token)) {
                logout(window.location)
            } else {
                const user = jwt.decode(token);
                return user;
            }
        } else {
            return null
        }
    } catch (ex) {
        return null
    }
}


export const isJwtExpired = (jwtToken) => {
    const token = jwtToken || getSessionToken();
    const user = token && jwt.decode(token);
    return !user
}


const isExternalUser = (username) => {
    const sessionUser = getCurrentUser();
    const loggedInUserName = username || (sessionUser && sessionUser.username);
    return (loggedInUserName && loggedInUserName.indexOf("@") > -1);

}

export const checkTokenValidity = (token) => {
    const userToken = token || getSessionToken();
    if (!userToken) {
        window.location.reload()
    } else if(isJwtExpired(userToken)) {
        logout(window.location);
    }
}
export const logout = (returnUrl) => {
    let loggedInUserSessionId;
    const token = getSessionToken();
    if(token){
        const currentUser = jwt.decode(token);
        if (currentUser != null) {
            loggedInUserSessionId = currentUser.sessionId;
        }
        //Removing the jwt token from local storage
        localStorage.removeItem(`QID-${process.env.REACT_APP_ENV_SN}-JWT-TOKEN`);
    }
    window.location = `${getLogoutUri()}?code=${loggedInUserSessionId}&targetUrl=${returnUrl}`;


};

export default {
    loginWithToken: setSession,
    logout,
    getCurrentUser,
    readTokenFromParams,
    isExternalUser
};

