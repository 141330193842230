const {
    REACT_APP_CONFIG_DATA_END_POINT: CONFIG_DATA_END_POINT = '',
    REACT_APP_REGISTRATION_SUBMIT_ENDPOINT: REGISTRATION_SUBMIT_ENDPOINT = '',
    REACT_APP_VALIDATE_TOKEN_ENDPOINT: VALIDATE_TOKEN_ENDPOINT = ''
} = process.env;

export const apiEndpoint = {
    configDataEndpoint: CONFIG_DATA_END_POINT,
    registrationSubmitEndpoint: REGISTRATION_SUBMIT_ENDPOINT,
    validateTokenEndPoint: VALIDATE_TOKEN_ENDPOINT
};
