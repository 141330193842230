import React from 'react';
import {ThemeProvider} from "@qc-modules/components";
import {defaultTheme} from "@qc-modules/styles";
import {default as GlobalStyles} from "./lib/global-styles";
import {AppLayout} from "./components/layout/AppLayout";

function App() {
    return (
        <>
            <GlobalStyles/>
            <ThemeProvider theme={defaultTheme}>
                <AppLayout/>
            </ThemeProvider>
        </>
    );
}

export default App;
