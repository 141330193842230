import * as Yup from 'yup';

import {maxLengthIfValueExist, minLengthIfValueExist} from "../validations";
import {registrationConstants} from "../registrationConstants";

const cityValidationRequired = (key) => {
    return (
        Yup.object().shape({
            [key]: Yup.string().trim()
                .required(registrationConstants.requiredMsg('City'))
                .min(registrationConstants.MIN_ADDRESS_CHARACTERS, registrationConstants.minLengthMsg('City', registrationConstants.MIN_ADDRESS_CHARACTERS))
                .max(registrationConstants.MAX_ADDRESS_CHARACTERS, registrationConstants.maxLengthMsg('City', registrationConstants.MAX_ADDRESS_CHARACTERS))
                .matches(registrationConstants.REGEX.CITY, registrationConstants.invalidCharacterMsg),
        })
    )
}

const countryValidationRequired = (key) => {
    return (
        Yup.object().shape({
            [key]: Yup.string().trim()
                .required(registrationConstants.requiredMsg('Country')),
        })
    )
}

const nameValidationRequired = (key) => {
    return (
        Yup.object().shape({
            [key]: Yup.string().trim()
                .required(registrationConstants.requiredMsg('Name'))
                .min(registrationConstants.MIN_NAME_CHARACTERS, registrationConstants.minLengthMsg("Name", registrationConstants.MIN_NAME_CHARACTERS))
                .max(registrationConstants.MAX_NAME_CHARACTERS, registrationConstants.maxLengthMsg("Name", registrationConstants.MAX_NAME_CHARACTERS))
                .matches(registrationConstants.REGEX.INITIAL_CHECK, registrationConstants.initialCheckMsg)
                .matches(registrationConstants.REGEX.FIRST_LAST_NAME, registrationConstants.invalidCharacterMsg),
        })
    )
}

const nameValidation = (key) => {
    return (
        Yup.object().shape({
            [key]: Yup.string().trim()
                .test('min_length_if_exist', registrationConstants.minLengthMsg('Name', registrationConstants.MIN_NAME_CHARACTERS), (value) => minLengthIfValueExist(value, registrationConstants.MIN_NAME_CHARACTERS))
                .test('max_length_if_exist', registrationConstants.maxLengthMsg('Name', registrationConstants.MAX_NAME_CHARACTERS), (value) => maxLengthIfValueExist(value, registrationConstants.MAX_NAME_CHARACTERS))
                .test('initial-check', registrationConstants.initialCheckMsg, (value)=> value ? new RegExp(registrationConstants.REGEX.INITIAL_CHECK).test(value) : true)
                .test('valid-char-check', registrationConstants.invalidCharacterMsg, (value)=> value ? new RegExp(registrationConstants.REGEX.FIRST_LAST_NAME).test(value) : true)
        })
    )
}

const companyNameValidationRequired = (key) => {
    return (
        Yup.object().shape({
            [key]: Yup.string().trim()
                .required(registrationConstants.requiredMsg('Company Name'))
                .min(registrationConstants.MIN_NAME_CHARACTERS, registrationConstants.minLengthMsg("Company Name", registrationConstants.MIN_NAME_CHARACTERS))
                .max(registrationConstants.MAX_NAME_CHARACTERS, registrationConstants.maxLengthMsg("Company Name", registrationConstants.MAX_NAME_CHARACTERS))
                .matches(registrationConstants.REGEX.COMPANY, registrationConstants.invalidCharacterMsg),
        })
    )
}

const taxNumValidation = (key) => {
    return (
        Yup.object().shape({
            [key]: Yup.string().trim()
                .required(registrationConstants.requiredMsg('Tax/VAT Registration Number Required'))
                .min(registrationConstants.MIN_NAME_CHARACTERS, registrationConstants.minLengthMsg("TAX/VAT Registration Number", registrationConstants.MIN_NAME_CHARACTERS))
                .max(registrationConstants.MAX_NAME_CHARACTERS, registrationConstants.maxLengthMsg("TAX/VAT Registration Number", registrationConstants.MAX_NAME_CHARACTERS))
                .matches(registrationConstants.REGEX.TAX_VAT, registrationConstants.invalidCharacterMsg),
        })
    )
}

const taxStatusValidation = (key) => {
    return (
        Yup.object().shape({
            [key]: Yup.string().trim()
                .test('min_length_if_exist', registrationConstants.minLengthMsg('Tax Status', registrationConstants.MIN_NAME_CHARACTERS), (value) => minLengthIfValueExist(value, registrationConstants.MIN_NAME_CHARACTERS))
                .test('max_length_if_exist', registrationConstants.maxLengthMsg('Tax Status', registrationConstants.MAX_NAME_CHARACTERS), (value) => maxLengthIfValueExist(value, registrationConstants.MAX_NAME_CHARACTERS))
                .matches(registrationConstants.REGEX.TAX_STATUS, registrationConstants.invalidCharacterMsg),
        })
    )
}

const taxStatusValidationRequired = (key) => {
    return (
        Yup.object().shape({
            [key]: Yup.string().trim()
                .required(registrationConstants.requiredMsg('Tax Status'))
        })
    )
}

const emailValidationRequired = (key) => {
    return (
        Yup.object().shape({
            [key]: Yup.string().trim()
                .required(registrationConstants.requiredMsg('Email'))
                .email(registrationConstants.invalidEmailMsg)
                .max(registrationConstants.MAX_EMAIL_CHARACTERS, `Your email address must be less than ${registrationConstants.MAX_EMAIL_CHARACTERS} characters.`)
                .matches(registrationConstants.REGEX.EMAIL, registrationConstants.invalidEmailMsg)
        })
    )
}

const emailValidation = (key) => {
    return (
        Yup.object().shape({
            [key]: Yup.string().trim()
                .email(registrationConstants.invalidEmailMsg)
                .max(registrationConstants.MAX_EMAIL_CHARACTERS, `Your email address must be less than ${registrationConstants.MAX_EMAIL_CHARACTERS} characters.`)
                .test('qcom-email-check', registrationConstants.invalidEmailMsg, (value)=> value ? new RegExp(registrationConstants.REGEX.EMAIL).test(value) : true)
        })
    )
}

const addressValidationRequired = (key) => {
    return (
        Yup.object().shape({
            [key]: Yup.string().trim()
                .required(registrationConstants.requiredMsg('Address'))
                .min(registrationConstants.MIN_ADDRESS_CHARACTERS, registrationConstants.minLengthMsg('Address', registrationConstants.MIN_ADDRESS_CHARACTERS))
                .max(registrationConstants.MAX_ADDRESS_CHARACTERS, registrationConstants.maxLengthMsg('Address', registrationConstants.MAX_ADDRESS_CHARACTERS))
                .matches(registrationConstants.REGEX.ADDRESS, registrationConstants.invalidCharacterMsg),
        })
    )
}

const addressValidation = (key) => {
    return (
        Yup.object().shape({
            [key]: Yup.string().trim()
                .test('min_length_if_exist', registrationConstants.minLengthMsg('Address', registrationConstants.MIN_ADDRESS_CHARACTERS), (value) => minLengthIfValueExist(value, registrationConstants.MIN_ADDRESS_CHARACTERS))
                .test('max_length_if_exist', registrationConstants.maxLengthMsg('Address', registrationConstants.MAX_ADDRESS_CHARACTERS), (value) => maxLengthIfValueExist(value, registrationConstants.MAX_ADDRESS_CHARACTERS))
                .matches(registrationConstants.REGEX.ADDRESS, registrationConstants.invalidCharacterMsg),
        })
    )
}

const phoneValidationRequired = (key) => {
    return (
        Yup.object().shape({
            [key]: Yup.string().trim()
                .required(registrationConstants.requiredMsg('Phone'))
                .min(registrationConstants.MIN_PHONE_CHARACTERS, registrationConstants.minLengthMsg('Phone', registrationConstants.MIN_PHONE_CHARACTERS))
                .max(registrationConstants.MAX_PHONE_CHARACTERS, registrationConstants.maxLengthMsg('Phone', registrationConstants.MAX_PHONE_CHARACTERS))
                .matches(registrationConstants.REGEX.PHONE_NUMBER, registrationConstants.invalidCharacterMsg),
        })
    )
}

const phoneValidation = (key) => {
    return (
        Yup.object().shape({
            [key]: Yup.string().trim()
                .test('min_length_if_exist', registrationConstants.minLengthMsg('Phone', registrationConstants.MIN_PHONE_CHARACTERS), (value) => minLengthIfValueExist(value, registrationConstants.MIN_PHONE_CHARACTERS))
                .test('max_length_if_exist', registrationConstants.maxLengthMsg('Phone', registrationConstants.MAX_PHONE_CHARACTERS), (value) => maxLengthIfValueExist(value, registrationConstants.MAX_PHONE_CHARACTERS))
                .matches(registrationConstants.REGEX.PHONE_NUMBER, registrationConstants.invalidCharacterMsg),
        })
    )
}


const stateValidationRequired = (key) => {
    return (
        Yup.object().shape({
            [key]: Yup.string().trim()
                .required(registrationConstants.requiredMsg('State'))
        })
    )
}

const postalCodeValidation = (key) => {
    return (
        Yup.object().shape({
            [key]: Yup.string().trim()
                .required(registrationConstants.requiredMsg('Postal Code'))
                .min(registrationConstants.MIN_ADDRESS_CHARACTERS, registrationConstants.minLengthMsg('Postal Code', registrationConstants.MIN_ADDRESS_CHARACTERS))
                .max(registrationConstants.MAX_ADDRESS_CHARACTERS, registrationConstants.maxLengthMsg('Postal Code', registrationConstants.MAX_ADDRESS_CHARACTERS))
                .matches(registrationConstants.REGEX.POSTAL_CODE, registrationConstants.invalidCharacterMsg),
        })
    )

}

const provinceValidation = (key) => {
    return (
        Yup.object().shape({
            [key]: Yup.string().trim()
                .test('min_length_if_exist', registrationConstants.minLengthMsg('Province', registrationConstants.MIN_PROVINCE_CHARACTERS), (value) => minLengthIfValueExist(value, registrationConstants.MIN_PROVINCE_CHARACTERS))
                .test('max_length_if_exist', registrationConstants.maxLengthMsg('Province', registrationConstants.MAX_PROVINCE_CHARACTERS), (value) => maxLengthIfValueExist(value, registrationConstants.MAX_PROVINCE_CHARACTERS))
                .matches(registrationConstants.REGEX.PROVINCE, registrationConstants.invalidCharacterMsg),
        })
    )
}

export const validations = {
    addressValidation,
    addressValidationRequired,
    cityValidationRequired,
    companyNameValidationRequired,
    countryValidationRequired,
    emailValidation,
    emailValidationRequired,
    nameValidation,
    nameValidationRequired,
    phoneValidation,
    phoneValidationRequired,
    postalCodeValidation,
    provinceValidation,
    stateValidationRequired,
    taxNumValidation,
    taxStatusValidation,
    taxStatusValidationRequired
};

