import React from 'react';
import {InfoBox, SectionHeading} from "./RegistrationStyle";

const OrgDetail = ({orgData={}}) => {
    return (
        <>
            <SectionHeading testid={'org-heading'} text="Editing info for:"/>
            <InfoBox testid={'org-detail-block'}>
                {orgData.name} (Org ID:{orgData.orgId})
            </InfoBox>
        </>
    )
}

export default OrgDetail;
