import React from "react"
import {bool, string, func} from "prop-types"
import css from "@styled-system/css"
import {Primitives, Glyph} from "@qc-modules/components";
import useEscapeClose from "./useEscapeClose";

const propTypes = {
  /** background color string */
  backgroundColor: string,
  /** Function called when the user clicks the close icon for the Modal */
  closeModal: func.isRequired,
  /** The React component to display inside the Modal */
  content: string,
  /** Sets modal to fill the screen at mobile sizes */
  showFullscreenMobile: bool,
  /** text color string */
  textColor: string
}

const modalWrapperStyles = {
  alignItems: "center",
  bottom: 3,
  justifyContent: "center",
  left: 0,
  position: "fixed",
  width: 1,
  zIndex: 500
}

const modalContentStyles = {
  borderRadius: 8,
  boxShadow:
    "0 2px 10px 0 rgba(74, 90, 117, 0.08), 0 20px 20px -12px rgba(74, 90, 117, 0.24)",
  mx: [2, 3, 160, 236, 331, 432],
  pl: [2, 3],
  position: "relative",
  pr: [5, 64],
  py: 12
}

const closeIconStyles = {
  cursor: "pointer",
  height: 16,
  position: "absolute",
  right: [12, 3],
  top: [12, 2],
  width: 16
}

const textStyles = {
  fontSize: 3,
  letterSpacing: "0.5px",
  lineHeight: 1.5,
  textAlign: "center"
}

const linkStyles = css({
  "& a": {
    color: "inherit"
  }
})

const ToastNotification = ({
  backgroundColor = "rgba(50, 83, 220, 0.1)",
  content,
  closeModal,
  textColor = "blue"
}) => {
  const close = useEscapeClose(closeModal)
  return (
    <Primitives.Flex {...modalWrapperStyles}>
      <Primitives.Flex
        backgroundColor={backgroundColor}
        {...modalContentStyles}
      >
        <Primitives.Button onClick={close} {...closeIconStyles}>
          <Glyph id='close' fill={textColor}/>
        </Primitives.Button>
        <Primitives.Text color={textColor} tag="p" {...textStyles}>
          <Primitives.RawHtml css={linkStyles} html={content} />
        </Primitives.Text>
      </Primitives.Flex>
    </Primitives.Flex>
  )
}

ToastNotification.propTypes = propTypes
export default ToastNotification
