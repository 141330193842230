import React from 'react';
import {SectionHeading} from "./RegistrationStyle";
import {Field} from "formik";
import {WrappedFormComponents} from "../../common/FormikWrappedComponents";
import {FieldRowFlex, FieldValidationError} from "./Helper";
import {Checkbox} from "@qc-modules/components";
import AdditionalContactForm from "./AdditionalContactForm";
import {DeliveryValidationFunctions} from "../../../utils/validation/DeliveryValidationFunctions";

const DeliveryForm = ({orgData, values, setFieldValue}) => {
    return (
        <>
            <SectionHeading text="Deliver to"/>

            <FieldRowFlex data-testid='ship-to-intermediate-consignee'>
                <Checkbox
                    name="shipToIntermediateConsignee"
                    id="shipToIntermediateConsignee"
                    isChecked={values.shipToIntermediateConsignee}
                    label={'Only use this if the Ship To is an Intermediate Consignee i.e., Freight Forwarder'}
                    checkboxOnChange={(e) => {
                        e.preventDefault();
                        setFieldValue("shipToIntermediateConsignee", !values.shipToIntermediateConsignee)
                    }}
                />
            </FieldRowFlex>

            {values.shipToIntermediateConsignee && <DeliveryFormsFields orgData={orgData} values={values}/>}
        </>
    )
}

export default DeliveryForm;


const DeliveryFormsFields = ({orgData, values}) => {
    const matchingCountry = orgData && orgData.countryStateMap && orgData.countryStateMap.find(obj => obj.code === values.deliveryCountry);
    const countryHasStates = matchingCountry && !!matchingCountry.states
    let stateOptions = matchingCountry && matchingCountry.states && matchingCountry.states.map((state) => ({
        id: state.code,
        value: state.code,
        label: state.name
    }));
    stateOptions && stateOptions.unshift({id: 'selectOne', value: '', label: 'Select One'});
    orgData.states = stateOptions;

    return (
        <>

            <FieldRowFlex data-testid={'deliveryLegalCompanyName'}>
                <Field name='deliveryLegalCompanyName' id='deliveryLegalCompanyName' label='Legal Company Name*'
                       type='text'
                       component={WrappedFormComponents.InputWrapper} width={1}
                       validate={DeliveryValidationFunctions.validateDeliveryCompanyName}/>
                <FieldValidationError name={'deliveryLegalCompanyName'}/>
            </FieldRowFlex>
            <FieldRowFlex>
                <Field name='deliveryAddress' id='deliveryAddress' label='Address*'
                       type='textarea' rows={3} height='90px'
                       component={WrappedFormComponents.InputWrapper}
                       width={1}
                       validate={DeliveryValidationFunctions.validateDeliveryAddress}
                />
                <FieldValidationError name={'deliveryAddress'}/>
            </FieldRowFlex>
            <FieldRowFlex>
                <Field name='deliveryCity' id='deliveryCity' label='City*' type='text'
                       component={WrappedFormComponents.InputWrapper}
                       width={1}
                       validate={DeliveryValidationFunctions.validateDeliveryCity}/>
                <FieldValidationError name={'deliveryCity'}/>
            </FieldRowFlex>
            <FieldRowFlex>
                <Field
                    as="select"
                    name="deliveryCountry"
                    id="deliveryCountry"
                    label="Country*"
                    labelPosition='placeholder'
                    width={1}
                    options={orgData.countryOptions}
                    component={WrappedFormComponents.SelectWrapper}
                    validate={DeliveryValidationFunctions.validateDeliveryCountry}
                />
                <FieldValidationError name={'deliveryCountry'}/>
            </FieldRowFlex>

            {(countryHasStates) &&
            <FieldRowFlex>
                <Field
                    as="select"
                    name="deliveryState"
                    id="deliveryState"
                    label="State*"
                    labelPosition='placeholder'
                    width={1}
                    options={orgData.states}
                    component={WrappedFormComponents.SelectWrapper}
                    validate={DeliveryValidationFunctions.validateDeliveryState}
                />
                <FieldValidationError name={'deliveryState'}/>
            </FieldRowFlex>
            }

            {(!countryHasStates) &&
            <FieldRowFlex>
                <Field name='deliveryProvince' id='deliveryProvince' label='Province' type='text'
                       component={WrappedFormComponents.InputWrapper} width={1}
                       validate={DeliveryValidationFunctions.validateDeliveryProvince}
                />
                <FieldValidationError name={'deliveryProvince'}/>
            </FieldRowFlex>
            }

            <FieldRowFlex>
                <Field name='deliveryPostalCode' id='deliveryPostalCode' label='Postal Code*' type='text'
                       component={WrappedFormComponents.InputWrapper} width={1}
                       validate={DeliveryValidationFunctions.validateDeliveryPostalCode}
                       bottomLabel={"If postal code does not apply, enter 0000"}
                />
                <FieldValidationError name={'deliveryPostalCode'}/>
            </FieldRowFlex>

            <FieldRowFlex>
                <Field name='deliveryContactName' id='deliveryContactName' label={'Primary Contact Name*'} type='text'
                       component={WrappedFormComponents.InputWrapper} width={1}
                       validate={DeliveryValidationFunctions.validateDeliveryName}
                />
                <FieldValidationError name={'deliveryContactName'}/>
            </FieldRowFlex>
            <FieldRowFlex>
                <Field name='deliveryContactPhone' id={'deliveryContactPhone'} label={'Primary Contact Phone*'}
                       type='text'
                       component={WrappedFormComponents.InputWrapper} width={1}
                       validate={DeliveryValidationFunctions.validateDeliveryPhone}
                />
                <FieldValidationError name={'deliveryContactPhone'}/>
            </FieldRowFlex>
            <FieldRowFlex>
                <Field name='deliveryEmail' id={'deliveryEmail'} label={'Primary Contact Email*'} type='text'
                       component={WrappedFormComponents.InputWrapper} width={1}
                       validate={DeliveryValidationFunctions.validateDeliveryEmail}
                />
                <FieldValidationError name={'deliveryEmail'}/>
            </FieldRowFlex>

            <FieldRowFlex>
                <AdditionalContactForm namespace={'deliveryAdditionalContacts'} values={values}/>
            </FieldRowFlex>
        </>
    )
}
