import React, {useEffect, useState} from 'react';
import ShippingForm from "./ShippingForm";
import DeliveryForm from "./DeliveryForm";
import ToastNotification from "../../toast-notification";

const ShippingAndDeliveryForm = ({orgData, values, setFieldValue}) => {
    const [showNotice, setShowNotice] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const closeTheModal = () => {
        setShowNotice(false);
    }

    const notificationStyles = {
        backgroundColor: "#d1d8f3",
        textColor: "#3253DC"
    }

    return (
        <>
            <ShippingForm orgData={orgData} values={values} setFieldValue={setFieldValue}/>

            <DeliveryForm orgData={orgData} values={values} setFieldValue={setFieldValue}/>

            {showNotice && <ToastNotification
                closeModal={closeTheModal}
                {...notificationStyles}
                content={'Note: Editing your Shipping Information will not change your Billing Information.'}
            />}

        </>
    )
}

export default ShippingAndDeliveryForm;
