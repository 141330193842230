import React from "react";
import {Link} from "react-router-dom";

const NotFound = () => (
    <div>
        <h2>The page you were looking for could not be found</h2>
        <Link to="/">Go home</Link>
    </div>
);

export default NotFound;