import React, {Fragment} from 'react';
import {FooterLink, FooterLinkContainer, StyledFooterCopyrightContainer, StyledFooterLegalText, StyledFooterLegalTextContainer} from "./FooterStyle";


export const FooterLinks = () => {
    const opacity = 1;
    const weight = 400;

    const linkOptions = {
        opacity,
        weight,
        tag: 'a',
        target: '_blank',
        rel: 'noopener noreferrer'
    };

    return (
        <FooterLinkContainer>
            {FooterLinkConfig.linkOptions.map((linkOption) => (
                <FooterLink key={linkOption.id}
                            href={linkOption.href} {...linkOptions}>{linkOption.displayText}</FooterLink>
            ))}
        </FooterLinkContainer>
    )
};


export const FooterCopyright = () => {
    return (
        <StyledFooterCopyrightContainer>
            <FooterCopyrightText/>
        </StyledFooterCopyrightContainer>
    )
};

export const FooterLinkConfig = {
    linkOptions:
        [
            {id: 'terms', href: 'https://www.qualcomm.com/site/terms-of-use', displayText: 'Terms of Use'},
            {id: 'privacy', href: 'https://www.qualcomm.com/site/privacy', displayText: 'Privacy'},
            {id: 'cookie', href: 'https://www.qualcomm.com/site/cookies', displayText: 'Cookie Policy'},
            {id: 'contact', href: 'https://www.qualcomm.com/contact', displayText: 'Contact Us'},
        ]
};


const currentYear = () => new Date().getFullYear();

export const FooterCopyrightText = () => {
    return (
        <Fragment>
            &copy; {currentYear()} Qualcomm Technologies, Inc. and/or its affiliated companies
        </Fragment>
    )
};

export const FooterLegalText = (props) => {
    const color = props.color ? props.color : "#BBC0C8";
    const opacity = color === 'white' ? .6 : 1;
    const weight = 400;

    const options = {
        color,
        opacity,
        weight
    };

    return (
        <StyledFooterLegalTextContainer>
            <StyledFooterLegalText {...options} as="p">Note: Certain product kits, tools and materials may require you
                to accept
                additional terms and conditions before accessing or using those items.</StyledFooterLegalText>

            <StyledFooterLegalText as="p">References to "Qualcomm" may mean Qualcomm Incorporated, or subsidiaries or
                business units
                within the Qualcomm corporate structure, as applicable.</StyledFooterLegalText>

            <StyledFooterLegalText {...options} as="p">
                Qualcomm Incorporated includes Qualcomm's licensing business, QTL, and the vast majority of its patent
                portfolio. Qualcomm Technologies, Inc., a wholly-owned subsidiary of Qualcomm Incorporated, operates,
                along with
                its subsidiaries, substantially all of Qualcomm's engineering, research and development functions, and
                substantially all of its products and services businesses. Qualcomm products referenced herein are
                products of
                Qualcomm Technologies, Inc. and/or its subsidiaries.
            </StyledFooterLegalText>
        </StyledFooterLegalTextContainer>
    )
};