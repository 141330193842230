import React, {Fragment} from 'react';
import {FooterCopyright, FooterLinks, FooterLegalText} from "./Footer";
import AppRouter from "../../routers/AppRouter";
import {
    AppContainer,
    BackgroundBox1,
    BackgroundBox2, ContentContainer, FooterContainer,
    HeaderContentContainer,
    QualcommLogo,
    QualcommShield, SubtitleContainer,
    TitleContainer
} from "./AppLayoutStyle";

export const AppLayout = () => {
    return (
        <AppContainer>
            <BackgroundBox1/>
            <BackgroundBox2/>
            <QualcommLogo/>

            <Fragment>
                <HeaderContentContainer>
                    <QualcommShield/>
                    <TitleContainer>Qualcomm ID</TitleContainer>
                    <SubtitleContainer>With a Qualcomm ID, you can access product information, such as
                        documentation, tools, software, support communities, and more.</SubtitleContainer>
                </HeaderContentContainer>
                <ContentContainer>
                    <AppRouter/>
                </ContentContainer>
            </Fragment>
            <FooterContainer>
                <FooterLinks/>
                <FooterCopyright/>
                <FooterLegalText/>
            </FooterContainer>
        </AppContainer>
    )
};