import styled from "styled-components";
import {Flex} from "@qc-modules/components";
import {ErrorMessage} from "formik";
import React from "react";

export const FieldRowFlex = styled(Flex)`
    flex-direction:column;
    margin-top: 25px;
    justify-content: space-around;
`;

export const MessageWrapper = ({children}) => (
    <StyledMessageWrapper>{children}</StyledMessageWrapper>
);

const StyledMessageWrapper = styled.div`
    font-size: 16px;
    margin: 2px 0;    
`;
const StyledMessage = styled.div`
    display: flex;
    align-items: center; 
    margin-bottom: 10px;
`;
export const StyledErrorMessage = styled(StyledMessage)`
    color: #E0182D;
`;

export const FieldValidationError = ({name}) => {
    return (
        <MessageWrapper>
            <StyledErrorMessage>
                <ErrorMessage name={name}/>
            </StyledErrorMessage>
        </MessageWrapper>
    )
}
