import React from 'react';
import {InputComponent, SelectComponent, Checkbox, Cta} from "@qc-modules/components";

const InputWrapper = ({field, form, ...props}) => {
    return (
        <InputComponent {...field} {...props}
                        error={Boolean(form.touched[field.name]) && Boolean(form.errors[field.name])}
                        success={Boolean(form.touched[field.name]) && Boolean(!form.errors[field.name])}
        />
    )
};

const SelectWrapper = ({field, form, ...props}) => {
    return (
        <SelectComponent {...field} {...props}
                         error={Boolean(form.touched[field.name]) && Boolean(form.errors[field.name])}
                         success={Boolean(form.touched[field.name] && !form.errors[field.name])}
        />

    )
};

const CheckboxWrapper = ({field, form, ...props}) => {
    return (
        <Checkbox {...field} {...props}
                  checkboxOnChange={form.handleChange}
                  isChecked={form.values[field.name]}
                  error={Boolean(form.touched[field.name]) && Boolean(form.errors[field.name])}
                  success={Boolean(form.touched[field.name] && !form.errors[field.name])}
        />


    )
};

const SubmitButtonWrapper = ({field, form, ...props})=> {
    return(
    <Cta {...field} {...props}
        version="secondary"
         role='button'
         data-testid='srf-save-button'
         type='submit'>
    </Cta>
    )
}

export const WrappedFormComponents = {
    InputWrapper: InputWrapper,
    SelectWrapper: SelectWrapper,
    CheckboxWrapper: CheckboxWrapper,
    SubmitButtonWrapper: SubmitButtonWrapper
};