import React from 'react'
import {validations} from "./ValidationSchema";
import {getInvalidGlyph, StyledErrorMessage} from "./ValidationStyles";

const validateShippingCompanyName = (value) => {
    const fieldName = 'legalCompanyName';
    const data = setupFieldData(fieldName, value);

    try {
        validations.companyNameValidationRequired(fieldName).validateSync(data);
    } catch (err) {
        return <StyledErrorMessage>{getInvalidGlyph()} {err.message}</StyledErrorMessage>
    }
}

const validateShippingAddress = (value) => {
    const fieldName = 'shippingAddress';
    const data = setupFieldData(fieldName, value);

    try {
        validations.addressValidationRequired(fieldName).validateSync(data);
    } catch (err) {
        return <StyledErrorMessage>{getInvalidGlyph()} {err.message}</StyledErrorMessage>
    }
}

const validateShippingEmail = (value) => {
    const fieldName = 'shippingEmail';
    const data = setupFieldData(fieldName, value);

    try {
        validations.emailValidationRequired(fieldName).validateSync(data);
    } catch (err) {
        return <StyledErrorMessage>{getInvalidGlyph()} {err.message}</StyledErrorMessage>
    }
}

const validateShippingState = (value) => {
    const fieldName = 'shippingState';
    const data = setupFieldData(fieldName, value);

    try {
        validations.stateValidationRequired(fieldName).validateSync(data);
    } catch (err) {
        return <StyledErrorMessage>{getInvalidGlyph()} {err.message}</StyledErrorMessage>
    }
}

const setupFieldData = (fieldName, value) => {
    return {[fieldName]: value};
}

const validateShippingPostalCode = (value) => {
    const fieldName = 'shippingPostalCode';
    const data = setupFieldData(fieldName, value);

    try {
        validations.postalCodeValidation(fieldName).validateSync(data);

    } catch (err) {
        return <StyledErrorMessage>{getInvalidGlyph()} {err.message}</StyledErrorMessage>
    }
}


const validateShippingProvince = (value) => {
    const fieldName = 'shippingProvince';
    const data = setupFieldData(fieldName, value);

    try {
        validations.provinceValidation(fieldName).validateSync(data);
    } catch (err) {
        return <StyledErrorMessage>{getInvalidGlyph()} {err.message}</StyledErrorMessage>
    }
}

const validateShippingCity = (value) => {
    const fieldName = 'shippingCity';
    const data = setupFieldData(fieldName, value);

    try {
        validations.cityValidationRequired(fieldName).validateSync(data);
    } catch (err) {
        return <StyledErrorMessage>{getInvalidGlyph()} {err.message}</StyledErrorMessage>
    }
}

const validateShippingCountry = (value) => {
    const fieldName = 'shippingCountry';
    const data = setupFieldData(fieldName, value);

    try {
        validations.countryValidationRequired(fieldName).validateSync(data);
    } catch (err) {
        return <StyledErrorMessage>{getInvalidGlyph()} {err.message}</StyledErrorMessage>
    }
}
const validateShippingName = (value) => {
    const fieldName = 'shippingName';
    const data = setupFieldData(fieldName, value);

    try {
        validations.nameValidationRequired(fieldName).validateSync(data);
    } catch (err) {
        return <StyledErrorMessage>{getInvalidGlyph()} {err.message}</StyledErrorMessage>
    }
}

const validateShippingPhone = (value) => {
    const fieldName = 'shippingPhone';
    const data = setupFieldData(fieldName, value);

    try {
        validations.phoneValidationRequired(fieldName).validateSync(data);
    } catch (err) {
        return <StyledErrorMessage>{getInvalidGlyph()} {err.message}</StyledErrorMessage>
    }
}

const validateTaxStatus = (value) => {
    const fieldName = 'shippingTaxStatus';
    const data = setupFieldData(fieldName, value);

    try {
        validations.taxStatusValidation(fieldName).validateSync(data);
    } catch (err) {
        return <StyledErrorMessage>{getInvalidGlyph()} {err.message}</StyledErrorMessage>
    }
}

const validateTaxStatusRequired = (value) => {
    const fieldName = 'shippingTaxStatus';
    const data = setupFieldData(fieldName, value);

    try {
        validations.taxStatusValidationRequired(fieldName).validateSync(data);
    } catch (err) {
        return <StyledErrorMessage>{getInvalidGlyph()} {err.message}</StyledErrorMessage>
    }
}

export const ShippingValidationFunctions = {
    validateShippingEmail,
    validateShippingAddress,
    validateShippingCity,
    validateShippingCompanyName,
    validateShippingCountry,
    validateShippingName,
    validateShippingPhone,
    validateShippingState,
    validateShippingPostalCode,
    validateShippingProvince,
    validateTaxStatus,
    validateTaxStatusRequired
};