export const minLengthIfValueExist = (value, length) => {
    if (value === "" || value === undefined) {
        return true
    } else {
        return isMinLength(value, length)
    }
}

export const isMinLength = (value, length) => {
    return value && (value.length >= length);
}


export const maxLengthIfValueExist = (value, length) => {
    if (value === "" || value === undefined) {
        return true
    } else {
        return isMaxLength(value, length)
    }
}

export const isMaxLength = (value, length) => {
    return value && (value.length <= length);
}
