import axios from 'axios';
import {apiEndpoint} from '../config';
import {getSessionToken, isTokenExpired, isValidSession, logout, refreshToken} from "../services/authService";

const securedRegisterAxios = axios.create({});

securedRegisterAxios.interceptors.request.use(async (config) => {
    let token = getSessionToken();
    if (!token || !isValidSession(token)) {
        logout(window.location)
    } else {
        if (isTokenExpired(token)) {
            const response = await refreshToken(token);
            token = response.data ? response.data.jwtToken : null;
        }
    }
    config.headers.Authorization = `Bearer ${token}`;
    return config;
}, function (error) {
    if (error && error.response && error.response.status === 401) {
        logout(window.location)
    } else {
        throw  error
    }
});

securedRegisterAxios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error && error.response && error.response.status === 401) {
        logout(window.location)
    } else {
        throw  error
    }
});

export const submitRegistration = async (data) => {
    Object.keys(data).forEach(k => data[k] = (typeof data[k] == 'string') ? data[k].trim() : data[k]);

    const requestConfig = {
        method: 'put',
        url: apiEndpoint.registrationSubmitEndpoint,
        data
    };

    return securedRegisterAxios(requestConfig);
};

export const validateToken = async () => {
    const requestConfig = {
        method: 'GET',
        url: apiEndpoint.validateTokenEndPoint,
    };

    const {data} = await securedRegisterAxios(requestConfig);
    const {oid_json, ...userRest} = data.user;
    const {json, ...orgRest} = data.org;

    let address = json.addressLine1;
    if (json.addressLine2) {
        address += `\n${json.addressLine2}`
    }

    const response = {
        user: {
            ...oid_json,
            ...userRest
        },
        org: {
            ...json,
            ...orgRest,
            address
        },
        countries: data.countries,
        usStates: data.usStates,
        inStates: data.inStates,
        taxStatus: data.taxStatus,
        countryStateMap: data.countryStateMap
    }

    return response;
};