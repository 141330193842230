import React from 'react';
import {Field, FieldArray} from "formik";
import {Box} from "@qc-modules/components";
import {WrappedFormComponents} from "../../common/FormikWrappedComponents";
import {FieldRowFlex, FieldValidationError} from "./Helper";
import {ActionLink} from "../../../utils/helper";
import {ValidationFunctions} from "../../../utils/validation/ValidationFunctions";
import {StyledContactContainer} from "./RegistrationStyle";

class Contact {
    constructor() {
        this.name = '';
        this.email = '';
        this.phone = ''
    }
}

const AdditionalContactForm = ({namespace, values}) => {
    let mapVals = values[`${namespace}`]

    const handleLink = (push) => {
        push(new Contact())
    }

    return (
        <FieldArray
            name={namespace}
            render={({remove, push}) => (
                <Box my={1}>
                    {mapVals.length > 0 &&
                    mapVals.map((contact, index) => (
                        <StyledContactContainer key={index}>
                            <Box align='right'>
                                <RemoveButton remove={remove} index={index}/>
                            </Box>
                            <Box width={1}>
                                {contactFormFields(namespace, index)}
                            </Box>

                        </StyledContactContainer>
                    ))}


                    <ActionLink mb={3} type={'button'} onClick={() => handleLink(push)}>+ Additional Contact</ActionLink>
                </Box>
            )}
        />
    )
}

export default AdditionalContactForm;

const RemoveButton = ({remove, index}) => {
    return (
        <ActionLink type={'button'} onClick={() => remove(index)}>&ndash; Remove</ActionLink>
    )
}

const contactFormFields = (namespace, index) => {
    return (
        <>
            <FieldRowFlex>
                <Field name={`${namespace}.${index}.name`} id={`${namespace}.${index}.name`}
                       label={'Additional Contact Name'} type='text'
                       component={WrappedFormComponents.InputWrapper} width={1}
                       validate={ValidationFunctions.validateContactName}/>
                <FieldValidationError name={`${namespace}.${index}.name`}/>
            </FieldRowFlex>

            <FieldRowFlex>
                <Field name={`${namespace}.${index}.phone`} id={`${namespace}.${index}.phone`}
                       label={'Additional Contact Phone'} type='text'
                       component={WrappedFormComponents.InputWrapper} width={1}
                       validate={ValidationFunctions.validateContactPhone}/>
                <FieldValidationError name={`${namespace}.${index}.phone`}/>
            </FieldRowFlex>

            <FieldRowFlex>
                <Field name={`${namespace}.${index}.email`} id={`${namespace}.${index}.email`}
                       label={'Additional Contact Email'} type='text'
                       component={WrappedFormComponents.InputWrapper} width={1}
                       validate={ValidationFunctions.validateContactEmail}/>
                <FieldValidationError name={`${namespace}.${index}.email`}/>
            </FieldRowFlex>
        </>
    )
}