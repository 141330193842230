import React from 'react';
import {Box} from '@qc-modules/components';
import {ComponentTitle} from "./RegistrationStyle";
import {registrationConstants} from "../../../utils/registrationConstants";
import {ActionLink} from "../../../utils/helper";
import history from "../../../context/History";

const RegisterResponse = (props = {}) => {
    const responseType = props.responseType ? props.responseType : props.location.responseType

    return (
        <>
            {responseType === registrationConstants.ResponseType.SUCCESS && <SuccessResponse/>}
            {responseType === registrationConstants.ResponseType.DUPLICATE && <PreviouslySubmittedResponse/>}
            {!responseType && <AccidentResponse/>}
        </>
    )
}

export default RegisterResponse;

const SuccessResponse = () => {
    return (
        <>
            <ComponentTitle testid={'reg-response-heading-success'} text={"Thank you for submitting your company information. Your customer account activation is currently in progress."}/>
        </>
    )
}

const PreviouslySubmittedResponse = () => {
    return (
        <>
            <ComponentTitle testid={'reg-response-heading-prev-submit'} text="This information has already been submitted by someone else at your company. Your customer account activation is currently in progress."/>
        </>
    )
}

const AccidentResponse = () => {
    return (
        <>
            <ComponentTitle text="You have reached this page by accident."/>

            <Box py={4}>
            <ActionLink type={'button'} onClick={()=>{history.push('/sales/activate')}}>Click to Continue</ActionLink>
            </Box>
        </>
    )
}