import React from 'react';
import styled from 'styled-components';
import {Flex, Text, iconMachineNameMap} from "@qc-modules/components";
import {colors} from "@qc-modules/styles";
import {device} from '../../lib/breakpoints'
import shield from "../../images/icon-shield-person.svg";

const LogoQualcommIcon = iconMachineNameMap["logo-qualcomm"];

export const BackgroundBox1 = styled.div`
display:none;
    @media ${device.mobile} {
        background-color: #3253DC;
        grid-column: 1/9;
        grid-row: 1/2;
        display:block;
        padding-bottom: 20px;
    }
    
    @media ${device.tablet} {
        grid-column: 1/11;
        padding-bottom: 0;
    }
    
    @media ${device.laptop} {
    grid-column: 1/8;
    grid-row: 1/4;
    }
`;

export const BackgroundBox2 = styled.div`
display:none;
    @media ${device.mobile} {
        background-color: #3253DC;
        grid-column: 1/9;
        grid-row: 2/3;
        display:block;
        padding-bottom: 20px;
    }
    
    @media ${device.tablet} {
        grid-column: 1/11;
        padding-bottom: 0px;
    }
    
    @media ${device.laptop} {
    display:none;
    }
    
`;

export const HeaderContentContainer = styled.div`
    padding-left: 16px;
    grid-area: header-container;
    background-color: #3253DC;
    color: white;
    display: flex;
    flex-wrap: wrap;
    padding-top: 40px;
    padding-bottom: 20px;
    
    @media ${device.mobile} {
    padding-left: 0;
    grid-column: 2/8;
    grid-row: 2/3;
    z-index: 4;
    padding-top: 0;
    }
    

    @media ${device.tablet} {
        padding-left: 0;
        grid-column: 3/11;
        display: grid;
        grid-template-columns: 85px 1fr;
        grid-template-rows: 1fr 1fr;
        align-items: flex-start;
       
    }
    
    @media ${device.laptop} {
        grid-column: 2/7;
        grid-row: 2/3;
        
        grid-template-columns: 1fr;
        grid-template-rows: auto fit-content(80px) fit-content(80px) fit-content(80px) auto;
        align-items: center;
        justify-content: center;
        
        align-self: center;
        padding-bottom: 0px;
    }
    
    @media ${device.jumbo} {
        grid-column: 3/7;
    }
`;

export const ContentContainer = styled.div`
    padding: 16px;
    grid-area: content;
    background-color: white;
    margin-bottom: 40px;
    align-self: center;
    
    @media ${device.mobile} {
        margin-bottom: 80px;
        margin-top: 40px;
        grid-row: 3/4;
        grid-column: 2/8;
        padding: 0;
    }
    
    
    @media ${device.tablet} {
        margin-top: 80px;
        grid-column: 3/9;
        margin-left: 0;
    }
    
    @media ${device.laptop} {
        margin-top: 0;
        grid-column: 9/14;
        grid-row: 2/3;
        margin-left: 0;
        margin-bottom: 0;
        max-width: 718px;
    }
`;

export const FooterContainer = styled.div`
    padding-left: 16px;
    grid-area: footer;
    display: flex;
    flex-direction: column;
    
    @media ${device.mobile} {
    padding-left: 0;
    grid-column: 2/8;
    grid-row: 4/5;
    align-self: end;
    }
    
    
     @media ${device.tablet} {
        grid-column: 2/10;
    }
    
     @media ${device.laptop} {
     grid-row: 3/4;
     grid-column: 1/17;
     z-index: 100;
     padding: 0;
     
     display: grid;
     
     grid-template-columns: 48px 48px repeat(11, 1fr) 48px 48px;
     grid-template-rows: 1fr fit-content(25px) fit-content(25px);
     margin-bottom: 48px;
     margin-top: 50px;
     
     align-items: end;
     
     }
`;


export const AppContainer = styled.div`
    height: 100vh;
    
    @media ${device.mobile} {
        display: grid;
        grid-template-columns: 24px repeat(6, 1fr) 24px;
        grid-template-rows: 85px 85px auto fit-content(175px);
    }
    
   @media ${device.tablet} {
   grid-template-rows: 90px 140px auto fit-content(175px);
    grid-template-columns: 48px repeat(8, 1fr) 48px;
   }
   
   @media ${device.laptop} {
    grid-template-columns: 48px 48px repeat(11, 1fr) 48px 48px;//total column count
    grid-template-rows: 90px auto fit-content(175px);
   }

    
`;


const LogoContainer = styled.div`
    padding-left: 16px;
    padding-top: 22px;
    flex-basis: 100%;
    background-color: #3253DC;
    display: block;
    
    @media ${device.mobile} {
    padding-left: 0;
    align-self: center;
    grid-column: 2/8;
    grid-row: 1/2;
    }
`;

export const QualcommLogo = () => {
    return (
        <LogoContainer><Logo/></LogoContainer>
    )
}

const ShieldContainer = styled(Flex)`
    padding-right: 16px;
        > img {
        width: 60px;
     }
     
    @media ${device.mobile} {
        grid-column: 1/3;
        grid-row: 2/3;
    }
    
    @media ${device.tablet} {
     > img {
        width: 80px;
     }
        grid-column: 1/2;
        grid-row: 1/3;
        justify-content: start;
    }
`;

export const QualcommShield = () => {
    return (
        <ShieldContainer><img src={shield} alt="Qualcomm ID Shield"/></ShieldContainer>
    )
}

export const TitleContainer = styled(Text)`
    font-size: 32px;
    white-space: nowrap;
    font-weight: 100;
    color: white;
    letter-spacing: -0.47px;
    line-height: 54px
    
    @media ${device.mobile} {
    align-self: center;
    }

    
     @media ${device.tablet} {
     font-size: 42px;
        grid-column: 2/3;
        grid-row: 1/2;
        padding-left: 20px;
    }
    
    @media ${device.laptop} {
        grid-column: 1/2;
        grid-row: 3/4;
        padding-left: 0;
     }
`;

export const SubtitleContainer = styled(Text)`
    display:none;
    
    
    @media ${device.tablet} {
        font-size: 14px;
        color:white;
        letter-spacing: .5px;
        display: block;
        
        grid-column: 2/2;
        grid-row: 2/3;
        padding-left: 20px;
        padding-right: 48px;
    }
    
    @media ${device.laptop} {
        font-size: 16px;
        grid-column: 1/2;
        grid-row: 4/5;
        padding-left: 0;
     }
`;

const Logo = styled(LogoQualcommIcon)`
  fill: ${props => colors("white", props)};
   width: 120px;
  
  
`;