import React, {useState} from 'react';
import {Flex} from "@qc-modules/components";
import {Field} from "formik";
import {WrappedFormComponents} from "../../common/FormikWrappedComponents";
import {FieldRowFlex, FieldValidationError} from "./Helper";
import {BillingValidationFunctions} from "../../../utils/validation/BillingValidationFunctions";
import QidTooltipComponent from "../../tooltip-component";

const AidContactForm = () => {
    const [toolTipOne, setToolTipOne] = useState(false)
    const [toolTipTwo, setToolTipTwo] = useState(false)

    const toggleToolTipOne = () => {
        setToolTipOne(!toolTipOne)
    }

    const toggleToolTipTwo = () => {
        setToolTipTwo(!toolTipTwo)
    }

    const bottomLabelOne = () => {
        return (
            <Flex>
                AID (automated invoice delivery)
                <QidTooltipComponent isOpen={toolTipOne} toggleIsOpen={toggleToolTipOne}>
                    {'In the event that a revenue invoice were to be sent, you would list a name/dept and an email address that would receive that invoice.'}
                </QidTooltipComponent>
            </Flex>
        )
    }

    const bottomLabelTwo = () => {
        return (
            <Flex>
                AID (automated invoice delivery)
                <QidTooltipComponent isOpen={toolTipTwo} toggleIsOpen={toggleToolTipTwo}>
                    {'In the event that a revenue invoice were to be sent, you would list a name/dept and an email address that would receive that invoice.'}
                </QidTooltipComponent>
            </Flex>
        )
    }

    return (
        <>
            <FieldRowFlex data-testid={'aidContactName'}>
                <Field id={'aidContactName'} name='aidContactName' label={'AID Contact Name*'} type='text'
                       component={WrappedFormComponents.InputWrapper} width={1}
                       bottomLabel={bottomLabelOne()}
                       validate={BillingValidationFunctions.validateAidName}
                />
                <FieldValidationError name={'aidContactName'}/>
            </FieldRowFlex>
            <FieldRowFlex data-testid={'aidContactEmail'}>
                <Field id={'aidContactEmail'} name='aidContactEmail' label={'AID Contact Email*'} type='text'
                       component={WrappedFormComponents.InputWrapper} width={1}
                       bottomLabel={bottomLabelTwo()}
                       validate={BillingValidationFunctions.validateAidEmail}
                />
                <FieldValidationError name={'aidContactEmail'}/>
            </FieldRowFlex>
        </>
    )
}

export default AidContactForm;
