import React, {useState} from 'react';
import {Cta, Box, Text} from "@qc-modules/components";
import {Form, Formik} from "formik";
import BillingAndContactForm from "./BillingAndContactForm";
import ShippingAndDeliveryForm from "./ShippingAndDeliveryForm";
import Loading from "../../common/Loading";
import {StyledButtonContainer} from "./RegistrationStyle";
import {ActionLink} from "../../../utils/helper";
import {StyledErrorMessage} from "./Helper";

const formSteps = [BillingAndContactForm, ShippingAndDeliveryForm]

const renderStep = (step, orgData, values, errors, touched, setFieldValue) => {
    const formProps = {
        orgData: {...orgData},
        values: {...values},
        errors: {...errors},
        touched: {...touched},
        setFieldValue: setFieldValue
    }

    return (
        React.createElement(formSteps[step - 1], {...formProps}, null)
    )
};


const RegistrationForm = ({handleSave, orgData, loading, ...props}) => {
    const [step, setStep] = useState(1);

    const countryOptions = orgData.countries.map((country) => ({
        id: country.code,
        value: country.code,
        label: country.name
    }))

    const usStateOptions = orgData.usStates.map((state) => ({
        id: state.code,
        value: state.code,
        label: state.name
    }))

    const inStateOptions = orgData.inStates.map((state) => ({
        id: state.code,
        value: state.code,
        label: state.name
    }))

    const taxStatusOptions = orgData.taxStatus.map((status) => ({
        id: status.id,
        value: status.description,
        label: status.description
    }))

    const selectOnePlaceholder = {id: 'selectOne', value: '', label: 'Select One'};

    countryOptions.unshift(selectOnePlaceholder);
    usStateOptions.unshift(selectOnePlaceholder);
    inStateOptions.unshift(selectOnePlaceholder);
    taxStatusOptions.unshift(selectOnePlaceholder);

    orgData.countryOptions = countryOptions;
    orgData.usStateOptions = usStateOptions;
    orgData.inStateOptions = inStateOptions;
    orgData.taxStatusOptions = taxStatusOptions;


    const handleBack = () => {
        window.scrollTo(0, 0)
        setStep(step => step - 1)
    }

    function isLastStep() {
        return step === formSteps.length
    }

    const handleSubmit = (values, helpers, errors) => {
        function removeEmptyAdditionalContacts(key) {
            let fieldName = `${key}AdditionalContacts`;
            let contacts = values[fieldName].filter(contact => contact.name || contact.phone || contact.email);
            values[fieldName] = contacts ? contacts : [];
        }

        function removeBlankContacts() {
            removeEmptyAdditionalContacts('billing');
            removeEmptyAdditionalContacts('shipping');
            removeEmptyAdditionalContacts('delivery');
        }

        function convertCountryDisplay() {
            const billingCountryDisplay = orgData.countries.find((country) => country.code === values.billingCountry);
            values.billingCountryDisplay = billingCountryDisplay.name;

            if (!!values.shippingCountry) {
                const shippingCountryDisplay = orgData.countries.find((country) => country.code === values.shippingCountry);
                values.shippingCountryDisplay = shippingCountryDisplay.name;
            }

            if (!!values.deliveryCountry) {
                const deliveryCountryDisplay = orgData.countries.find((country) => country.code === values.deliveryCountry);
                values.deliveryCountryDisplay = deliveryCountryDisplay.name;
            }
        }

        function fetchStates(country) {
            const matchingCountry = orgData.countryStateMap && orgData.countryStateMap.find(obj => obj.code === country);
            return matchingCountry && (matchingCountry.states || []);
        }

        function convertStateDisplayOrProvince() {

            const billingCountryStates = fetchStates(values.billingCountry)
            if (!!billingCountryStates && billingCountryStates.length) {
                delete values['billingProvince'];
                const billingStateDisplay = billingCountryStates.find((state) => state.code === values.billingState);
                values.billingStateDisplay = billingStateDisplay.name;

            } else {
                delete values['billingState'];
            }

            const shippingCountryStates = fetchStates(values.shippingCountry)
            if (!!shippingCountryStates && shippingCountryStates.length) {
                delete values['shippingProvince'];
                const shippingStateDisplay = shippingCountryStates.find((state) => state.code === values.shippingState);
                values.shippingStateDisplay = shippingStateDisplay.name;
            } else {
                delete values['shippingState'];
            }

            const deliveryCountryStates = fetchStates(values.deliveryCountry)
            if (!!deliveryCountryStates && deliveryCountryStates.length) {
                delete values['deliveryProvince'];
                const deliveryStateDisplay = deliveryCountryStates.find((state) => state.code === values.deliveryState);
                values.deliveryStateDisplay = deliveryStateDisplay.name;

            } else {
                delete values['deliveryState'];
            }
        }

        function convertOrgDisplay() {
            values.organization = orgData.org.organization
            values.orgId = orgData.org.orgId
            values.orgName = orgData.org.name
        }

        function prepareDataForSubmit() {
            convertOrgDisplay();
            convertCountryDisplay();
            convertStateDisplayOrProvince();
            removeBlankContacts();
        }

        if (isLastStep()) {
            prepareDataForSubmit();
            handleSave(values, helpers);
            helpers.setSubmitting(true);
        } else {
            setStep(step => step + 1);
            helpers.setSubmitting(false)
        }
    }

    const SaveButton = ({label, ...props}) => {
        return (
            <Cta {...props}
                 type={'submit'}
                 version="secondary"
                 data-testid='srf-save-button'
                 role='button'
                 tag={'button'}
            >
                {label}
            </Cta>
        )
    }

    const BackButton = () => {
        return (
            <ActionLink type={'button'} onClick={handleBack} data-testid='srf-back-button'>Return to billing
                information</ActionLink>
        )
    }

    return (
        <Formik
            {...props}
            initialValues={formData(orgData)}
            onSubmit={(values, helpers) => handleSubmit(values, helpers)}
        >
            {({values, isValid, errors, touched, isSubmitting, submitCount, setFieldValue}) => {
                return (
                    <>
                        <Form autoComplete="off">

                            {loading && <Loading/>}
                            {renderStep(step, orgData, values, errors, touched, setFieldValue)}

                            {!isValid && submitCount > 0 && <Box my={2}>
                                <StyledErrorMessage>
                                    <Text fontSize={4}>There are errors or missing data in the form. Please review your
                                        entries.</Text>
                                </StyledErrorMessage>
                            </Box>}
                            <StyledButtonContainer>
                                <SaveButton label={isLastStep() ? 'Submit' : 'NEXT: Shipping and Delivery'}
                                            disabled={isSubmitting}/>
                                {step > 1 && <BackButton/>}

                            </StyledButtonContainer>
                        </Form>

                    </>
                )
            }


            }
        </Formik>
    )
}

export default RegistrationForm;

const formData = (orgData) => {
    return (
        {
            billingAddress: (orgData.org.address || ''),
            billingCity: orgData.org.city || '',
            billingState: orgData.org.state || '',
            billingPostalCode: orgData.org.postalCode || '',
            billingProvince: orgData.org.province || '',
            billingCountry: orgData.org.country || '',
            billingTaxNum: '',
            billingName: (orgData.user.firstName || '') + ' ' + (orgData.user.lastName || ''),
            billingPhone: orgData.user.telephoneNumber || '',
            billingEmail: orgData.user.username || '',
            aidContactName: '',
            aidContactEmail: '',

            billingAdditionalContacts: [],
            shippingAdditionalContacts: [],
            deliveryAdditionalContacts: [],

            shippingLegalCompanyName: '',
            shippingAddress: '',
            shippingCity: '',
            shippingState: '',
            shippingPostalCode: '',
            shippingProvince: '',
            shippingCountry: '',
            shippingContactName: '',
            shippingContactPhone: '',
            shippingEmail: '',
            shippingTaxStatus: '',
            shippingSameAsBilling: true,

            deliveryLegalCompanyName: '',
            deliveryAddress: '',
            deliveryCity: '',
            deliveryState: '',
            deliveryPostalCode: '',
            deliveryProvince: '',
            deliveryCountry: '',
            deliveryContactName: '',
            deliveryContactPhone: '',
            deliveryEmail: '',

            shipToIntermediateConsignee: false,
        }
    )
};