import React from 'react'
import {validations} from "./ValidationSchema";
import {getInvalidGlyph, StyledErrorMessage} from "./ValidationStyles";

const validateBillingAddress = (value) => {
    const fieldName = 'billingAddress';
    const data = setupFieldData(fieldName, value);

    try {
        validations.addressValidationRequired(fieldName).validateSync(data);
    } catch (err) {
        return <StyledErrorMessage>{getInvalidGlyph()} {err.message}</StyledErrorMessage>
    }
}

const validateBillingEmail = (value) => {
    const fieldName = 'billingEmail';
    const data = setupFieldData(fieldName, value);

    try {
        validations.emailValidationRequired(fieldName).validateSync(data);
    } catch (err) {
        return <StyledErrorMessage>{getInvalidGlyph()} {err.message}</StyledErrorMessage>
    }
}

const validateBillingState = (value) => {
    const fieldName = 'billingState';
    const data = setupFieldData(fieldName, value);

    try {
        validations.stateValidationRequired(fieldName).validateSync(data);
    } catch (err) {
        return <StyledErrorMessage>{getInvalidGlyph()} {err.message}</StyledErrorMessage>
    }
}

const setupFieldData = (fieldName, value) => {
    return {[fieldName]: value};
}

const validateBillingPostalCode = (value) => {
    const fieldName = 'billingPostalCode';
    const data = setupFieldData(fieldName, value);

    try {
        validations.postalCodeValidation(fieldName).validateSync(data);

    } catch (err) {
        return <StyledErrorMessage>{getInvalidGlyph()} {err.message}</StyledErrorMessage>
    }
}


const validateBillingProvince = (value) => {
    const fieldName = 'billingProvince';
    const data = setupFieldData(fieldName, value);

    try {
        validations.provinceValidation(fieldName).validateSync(data);
    } catch (err) {
        return <StyledErrorMessage>{getInvalidGlyph()} {err.message}</StyledErrorMessage>
    }
}

const validateBillingCity = (value) => {
    const fieldName = 'billingCity';
    const data = setupFieldData(fieldName, value);

    try {
        validations.cityValidationRequired(fieldName).validateSync(data);
    } catch (err) {
        return <StyledErrorMessage>{getInvalidGlyph()} {err.message}</StyledErrorMessage>
    }
}

const validateBillingCountry = (value) => {
    const fieldName = 'billingCountry';
    const data = setupFieldData(fieldName, value);

    try {
        validations.countryValidationRequired(fieldName).validateSync(data);
    } catch (err) {
        return <StyledErrorMessage>{getInvalidGlyph()} {err.message}</StyledErrorMessage>
    }
}
const validateBillingName = (value) => {
    const fieldName = 'billingName';
    const data = setupFieldData(fieldName, value);

    try {
        validations.nameValidationRequired(fieldName).validateSync(data);
    } catch (err) {
        return <StyledErrorMessage>{getInvalidGlyph()} {err.message}</StyledErrorMessage>
    }
}

const validateBillingTaxNum = (value) => {
    const fieldName = 'billingTaxNum';
    const data = setupFieldData(fieldName, value);

    try {
        validations.taxNumValidation(fieldName).validateSync(data);
    } catch (err) {
        return <StyledErrorMessage>{getInvalidGlyph()} {err.message}</StyledErrorMessage>
    }
}

const validateBillingPhone = (value) => {
    const fieldName = 'billingPhone';
    const data = setupFieldData(fieldName, value);

    try {
        validations.phoneValidationRequired(fieldName).validateSync(data);
    } catch (err) {
        return <StyledErrorMessage>{getInvalidGlyph()} {err.message}</StyledErrorMessage>
    }
}

const validateAidEmail = (value) => {
    const fieldName = 'aidContactEmail';
    const data = setupFieldData(fieldName, value);

    try {
        validations.emailValidationRequired(fieldName).validateSync(data);
    } catch (err) {
        return <StyledErrorMessage>{getInvalidGlyph()} {err.message}</StyledErrorMessage>
    }
}

const validateAidName = (value) => {
    const fieldName = 'aidContactName';
    const data = setupFieldData(fieldName, value);

    try {
        validations.nameValidationRequired(fieldName).validateSync(data);
    } catch (err) {
        return <StyledErrorMessage>{getInvalidGlyph()} {err.message}</StyledErrorMessage>
    }
}

export const BillingValidationFunctions = {
    validateAidEmail,
    validateAidName,
    validateBillingEmail,
    validateBillingAddress,
    validateBillingCity,
    validateBillingCountry,
    validateBillingName,
    validateBillingTaxNum,
    validateBillingPhone,
    validateBillingState,
    validateBillingPostalCode,
    validateBillingProvince,
};